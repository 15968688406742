<template lang="pug">
    #SmsSupplierSetting
        loadingOverlay(v-if='loading' message='Loading . . .' :zIndex='999')
        h3.d-flex.align-center.setting-title.px-4.py-3
            .cube-icon.small.bg-primary.mr-3
                v-icon(size='18' color='white') fa-solid fa-hashtag
            span {{$t('SETTING.SMSVENDOR')}}
        .setting-inner
            v-col(cols='12' md='12' )
                v-form(v-model="valid", ref="form")
                    p
                        label(for='personalMail') {{$t('SMSVENDOR.VENDOR')}}
                        v-select(v-model="vendor_data.vendor", solo dense :items="supplier_list",item-text="name",item-value="key")
                    p(v-if='vendor_data.vendor!="DEFAULT"')
                        label {{$t('SMSVENDOR.DOMAIN')}}
                            v-tooltip(right)
                                template(v-slot:activator='{ on, attrs }')
                                    v-icon(class='circle_fill_information' v-bind='attrs' v-on='on')
                                p.mb-0 {{$t('SMSVENDOR.TIP')}}
                                span {{$t('SMSVENDOR.TIP_2')}}
                        v-row(no-gutters)
                            v-col.pr-2
                                v-text-field(v-model='vendor_data.url', solo dense :rules="formRule.urlRules")
                    p(v-if='vendor_data.vendor!="DEFAULT"')
                        label {{$t('SMSVENDOR.USER_NAME')}}
                        v-row(no-gutters)
                            v-col.pr-2
                                v-text-field(v-model='vendor_data.user_name', solo dense :rules="formRule.requiredRules")
                    p(v-if='vendor_data.vendor!="DEFAULT"')
                        label {{$t('SMSVENDOR.USER_PWD')}}
                        v-row(no-gutters)
                            v-col.pr-2
                                v-text-field(v-model='vendor_data.password', solo dense :rules="formRule.requiredRules" :append-icon="iconType" :type="passwordType" @click:append="showPwd")
                    p
                    v-row.mt-10.justify-center(no-gutters)
                        v-col(cols='12' md='6')
                            v-btn(:ripple='false' width='100%' height='45' color='success' depressed dark @click='onSave()') {{$t('GENERAL.SAVE')}}
                                
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
                message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
                success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
                error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
	import Vue from 'vue';
    import messageDialog from '@/components/Dialog/messageDialog';    
    import PreferenceService from "@/services/PreferenceService";
    import successDialog from "@/components/Dialog/successDialog";
    import errorDialog from "@/components/Dialog/errorDialog";
    import loadingOverlay from "@/components/Common/loadingOverlay";
    import i18n from '/common/plugins/vue-i18n.js';

	export default Vue.extend({
        props: {  
            
        },
        components: {   
                loadingOverlay,
                messageDialog,
                successDialog,
                errorDialog,
            },        
        data() {
		return {
            vendor_data:{
                vendor:"DEFAULT",
                url:"",
                user_name:"",
                password:"",
                owner_phone:"",
            },
            supplier_list:[{key:"DEFAULT",name:i18n.t('SMSVENDOR.DEFAULT')},{key:"MITAKE",name:i18n.t('SMSVENDOR.MITAKE')}],
            loading:false,
            errorMessage:"",
            message:"",
            messageDialog:false,
            errorDialog:false,
            successDialog:false,
            valid:false,
            formRule: {
                requiredRules: [
                    (v) => !!v || i18n.t("RULE.RULE_R")
                ],
                urlRules: [
                    (v) => !!v || i18n.t("RULE.RULE_R"),
                    (v) => /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$|(^$)|null/.test(v) || i18n.t('RULE.RULE_URL'),
                    (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200')
                ],
            },
            passwordType: "password",
            iconType:"fa fa-eye-slash",
        }},
        created()
        {            
            PreferenceService.getSmsVendor().then(response => 
            {
                this.vendor_data = response.data;
            })
            .catch((response) => {
                this.showErrorDialog(response);
            });
        },
        methods:{
            showPwd() {
            if (this.passwordType === "password") {
                this.passwordType = "text";
                this.iconType ="fa fa-eye";
            } else {
                this.passwordType = "password";
                this.iconType ="fa fa-eye-slash";
            }      
            },
            validateForm(){
                this.valid = this.$refs.form.validate();
                if(this.valid)                    
                    return true;
                else
                    return false;
            },
            showSuccessDialog() {
                this.successDialog = true;
            },
            onEmitSuccessDialog() {
                this.successDialog = false;
             },
            showErrorDialog(message) {  
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            onEmitMessageDialog(val) {
                if(val)
                {
                    this.editData.number_record = this.editData.start_number 
                    this.onSave(this.editType,this.editData)
                }else
                {
                    this.unchange = true
                    this.onSave(this.editType,this.editData)
                }
                this.messageDialog = false; 
            }, 
            onSave()
            {
                if(this.validateForm() ){
                    
                   PreferenceService.update("SMS_VENDOR",this.vendor_data)
                   .then(()=>{
                        this.showSuccessDialog();
                   })
                }
            },
        },
        watch:{}
	});
</script>

<style lang="css">
</style>