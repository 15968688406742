<template lang="pug">
#CustomFields
  loadingOverlay(v-if='loading' message='Loading . . .' :zIndex='999')
  v-row.setting-title.align-center.py-3.px-4(no-gutters)
    v-col(cols="12", md="9")
      h3.d-flex.align-center
        .cube-icon.small.bg-primary.mr-3
          v-icon(size="19", color="white") fas fa-bullhorn
        span {{$t('SETTING.NOTIFICATIONSETTING')}}
    v-col.text-md-right.w-100(cols="12", md="3")
      v-btn(@click="showAddNoticeDialog" height="40" color="green" depressed dark)
        v-icon.mr-1(size="14") icon-add
        span {{$t('NOTIFICATIONSETTING.ADD_NOTICE')}}
  .px-4.py-3 
    v-row.align-center(no-gutters)
      span.mr-2.ml-3 {{$t('NOTIFICATIONSETTING.TIMER')}}
      template
        v-col(cols='3')
          v-slider(v-if='onEditTimer' v-model="secondSetting" color="orange" min="1" max="60" thumb-label="always" dense value=secondSetting)
          v-slider(v-else color="red" v-model="secondSetting" min="1" max="60" readonly thumb-label="always" :thumb-size="26" dense value=secondSetting)
            span {{secondSetting}}
      span.mx-2 {{$t('NOTIFICATIONSETTING.SECONDTIMES')}}
      template
          v-btn(v-if='!onEditTimer' icon @click="editTimer()")
            v-icon(size="18" ) icon-edit
          v-btn(v-if='onEditTimer' icon @click="closeEdit()")
            v-icon(size="18" color="red") mdi-window-close
          v-btn(v-if='onEditTimer' icon @click="saveTimer(secondSetting)")
            v-icon(size="18" color="green") fas fa-check          
    section
      v-data-table.dashboard-table(
        :headers="noticeHeader",
        :items="noticeList",
        :items-per-page="itemPerPage",
        :page.sync="page",
        @page-count="pageCount = $event",
        multi-sort
        fixed-header=true,
        hide-default-footer="hide-default-footer",
        dense
      )
        template( v-slot:item.title="{ item }" )
            .ellipsis.pl-3
                span(:title='item.title') {{item.title}}      
        template( v-slot:item.content="{ item }")
             .ellipsis
                span(:title='item.content') {{item.content}} 
        template( v-slot:item.active="{ item }")
            span(v-if='item.active === true') {{$t('NOTIFICATIONSETTING.ACTIVATED')}}  
            span(v-else) {{$t('NOTIFICATIONSETTING.UNACTIVATED')}}              
        template(v-slot:item.action="{ item }")
          v-btn(icon @click="showEditNoticeDialog( item )")
            v-icon(size="18" ) icon-edit
          v-btn(icon @click="onDelete( item )")
            v-icon(size="18") icon-remove        
      v-pagination.py-1.mb-3(v-model='page' :length='pageCount' v-if='pageCount > 1' circle :total-visible="10") 

    v-dialog(v-model="AddOrEditDialog" max-width="500" content-class="setting-dialog" v-if="AddOrEditDialog")
      v-card
        v-card-title.d-flex.justify-center {{addOrEdit}} {{$t('NOTIFICATIONSETTING.NOTICE')}}
        v-card-text
          v-form(v-model="addOrEditNoticeData.valid", ref="form")
            v-text-field.no-border.v-text-field__slot(
              v-model="addOrEditNoticeData.title",
              type="text",
              :label=`$t('NOTIFICATIONSETTING.TITLE')`,
              prepend-icon="mdi-mail",
              :rules="formRule.titleRule"
            )
            v-textarea.textarea-style.no-border.v-text-field__slot(
              v-model="addOrEditNoticeData.content",
              type="text",
              :label=`$t('NOTIFICATIONSETTING.CONTENT')`,
              prepend-icon="mdi-equal-box",   
              no-resize           
              :rules="formRule.fieldRules"
            )
            v-select.no-border.v-text-field__slot(
                v-model="addOrEditNoticeData.active", 
                :items="status_list",
                :label=`$t('NOTIFICATIONSETTING.STATUS')`,
                prepend-icon="mdi-wrench", 
                item-text="name",
                item-value="key"
            )
          v-card-actions.mt-8.px-0.py-0
            v-row(no-gutters)
              v-col.pr-2(cols="6")
                v-btn(
                  :ripple="false",
                  @click="onCancel",
                  width="100%",
                  height="40",
                  color="cancel",
                  depressed,
                  dark
                ) {{$t('GENERAL.CANCEL')}}
              v-col.pl-2(cols="6")
                v-btn(
                  :ripple="false",
                  @click="onAddOrEditNotice(addOrEditNoticeData)",
                  width="100%",
                  height="40",
                  color="success",
                  depressed,
                  dark
                ) {{ addOrEditButton }}

    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
        message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
        success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')            
</template>

<script>
import Vue from "vue";
import NotificationDataService from "@/services/NotificationDataService";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import loadingOverlay from "@/components/Common/loadingOverlay";    
import i18n from '/common/plugins/vue-i18n.js' 
import passData from "@/services/passData.js";

export default Vue.extend({
  components: {
    loadingOverlay, 
    messageDialog,
    successDialog,
    errorDialog,
  },
  data() {
    return {
      addOrEdit: i18n.t('GENERAL.ADD'),
      addOrEditButton: i18n.t('GENERAL.ADD'),
      loading: false,    
      status_list:[{key:true,name:i18n.t('NOTIFICATIONSETTING.ACTIVATED')},{key:false,name:i18n.t('NOTIFICATIONSETTING.UNACTIVATED')}],
      //- dialog
      messageDialog: false,
      message: '',
      successDialog: false,      
      errorDialog: false,   
      errorMessage: "", 
      AddOrEditDialog: false,
      onEditTimer: false,
      //- table
      noticeHeader: [
        {
          text: i18n.t('NOTIFICATIONSETTING.TITLE'),
          value: "title",
          align: "left",
          width: '25%',
          class: "blue lighten-5",
          sortable: false      
        },
        {
          text: i18n.t('NOTIFICATIONSETTING.CONTENT'), 
          value: "content",
          align: "left",
          width: '45%',
          class: "blue lighten-5",
          sortable: false
        },
        {
          text: i18n.t('NOTIFICATIONSETTING.STATUS'), 
          value: "active",
          align: "left",
          width: '15%',
          class: "blue lighten-5",
          sortable: false
        },
        {
          text: i18n.t('NOTIFICATIONSETTING.ACTIVE'),
          value: "action",
          align: "center",
          width: '15%',
          class: "blue lighten-5",
          sortable: false
        },
      ],
      page: 1,
      pageCount: 1,
      itemPerPage: 10,
      //- data
      noticeList:[],
      addOrEditNoticeData: this.initializeAddOrEditNotice(),
      delId: null,
      originalItem: {},
      secondSetting: null,
      //- form rule
      formRule: {
        fieldRules: [(v) => !!v || i18n.t('RULE.RULE_R')],
        titleRule:[
          (v) => !!v || i18n.t('RULE.RULE_R'),
          (v) => v.length <= 100 || i18n.t('RULE.RULE_100')],
      },
    };
  },
  created() {
    this.getNotificationTimer(),
    NotificationDataService.noticelist()
    .then((response)=>{
      if(response.data.length > 0){
        this.noticeList = response.data;
      }
    });
  },
  methods: {
    validateForm() {
      if (this.$refs.form.validate()) {
        return true;
      } else {
        return false;
      }
    },
    initializeAddOrEditNotice() {
        return {
            title: "",
            content: "",
            active:true
        };
    },
    getDuplicateNameMessage() {
      return i18n.t('NOTIFICATIONSETTING.ERROR');
    },
    getWrongInputErrorMessage() {
      return i18n.t('NOTIFICATIONSETTING.ERROR2');
    },  
    // 打開新增視窗
    showAddNoticeDialog() {
      this.AddOrEditDialog = true;
      this.addOrEdit = i18n.t('GENERAL.ADD');
      this.addOrEditButton = i18n.t('GENERAL.ADD');
      this.addOrEditNoticeData = this.initializeAddOrEditNotice();
    },
    // 新增
    onAdd(data) {
      this.loading = true;
      NotificationDataService.createnotice(data)
      .then(response => {
        if(response.data.id){
          this.noticeList.unshift(response.data);
          this.showSuccessDialog();
          this.loading = false;
          passData.$emit("isUpdate",true);
        }
      });
      this.AddOrEditDialog = false;
    },
    // 打開編輯視窗
    showEditNoticeDialog(data) {
      this.AddOrEditDialog = true;
      this.addOrEdit = i18n.t('GENERAL.EDIT');
      this.addOrEditButton = i18n.t('GENERAL.SAVE');
      this.addOrEditNoticeData = JSON.parse(JSON.stringify(data));
    }, 
    // 儲存編輯
    async OnSave(data) {
      this.loading = true;
      await NotificationDataService.editnotice(data)
        .then((response)=>{
          if(response.data.id){
              let editIndex = this.noticeList.findIndex(n => n.id == this.addOrEditNoticeData.id);
              this.$set(this.noticeList,editIndex,response.data)
              this.showSuccessDialog();
              this.loading = false;
              passData.$emit("isUpdate",true);
          }
        })
      this.AddOrEditDialog = false;
    },  
    async onAddOrEditNotice(data) {        
      if(this.validateForm())
      {
        if (data.id) {      // Edit
            if(this.noticeList.find(n => n.title == data.title && n.id != data.id))
            {
                this.showErrorDialog(this.getDuplicateNameMessage());
                return;
            }
            await this.OnSave(data);
        } else {            // Add
            if(this.noticeList.find(n => n.title == data.title))
            {
                this.showErrorDialog(this.getDuplicateNameMessage());
                return;
            }
            await this.onAdd(data);
        }
        this.addOrEditNoticeData = this.initializeAddOrEditNotice()
      }
    },   
    // 刪除確認
    onDelete(item) {
      this.showMessageDialog(i18n.t('NOTIFICATIONSETTING.MESSAGE'));
      this.delId = item.id;
    },
    //Close AddOrEditDialog
    onCancel() {
      this.AddOrEditDialog = false;
    },
    //delete、error、success Dialog
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },   
    onEmitMessageDialog(val) {
      this.messageDialog = false;
      if (val) {
        NotificationDataService.deletenotice(this.delId)
        .then(() => {
          let delIndex = this.noticeList.findIndex(n => n.id == this.delId);
          this.noticeList.splice(delIndex, 1);
          this.delId = null;
          this.showSuccessDialog();
          passData.$emit("isUpdate",true);
        }).catch((response) => {
            this.showErrorDialog(response.data);
        });
      }
    },
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {  
      this.errorDialog = true;      
      this.errorMessage = message;
    },   
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    getNotificationTimer() {
      NotificationDataService.getTimer()
        .then((response)=>{
          this.secondSetting = parseInt(response.data)/1000
        });
    },
    //打開編輯timer
    editTimer() {
      this.onEditTimer = true;
    },
    //儲存編輯timer
    saveTimer(updatedVal) {
      updatedVal = updatedVal * 1000;
      NotificationDataService.editTimer(updatedVal);
      this.onEditTimer = false;
      passData.$emit("updateTimer",updatedVal);
      passData.$emit("isUpdate",true);
    },
    //關閉編輯timer
    closeEdit() {
      this.onEditTimer = false;
      this.getNotificationTimer();
    }
  }
})
</script>