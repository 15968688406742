<template lang="pug">
#PipelineStageSetting
    v-row.setting-title.align-center.px-4.py-3(no-gutters)
        v-col(cols='12' md='6')
            h3.d-flex.align-center.mb-0
                .cube-icon.small.bg-primary.mr-3
                    v-icon(size='19' color='white') icon-step
                span {{$t('SETTING.PIPELINESTAGESETTING')}}
    .px-5.py-5
        v-row(no-gutters).mb-2
            v-col.d-flex(cols='8')
                label {{$t('CUSTOMFIELDS.SELECT_PAGE')}}
                    v-select.mr-4(v-model='stage_type' :items='typeList' item-value='key' item-text='name' prepend-inner-icon='icon-step' height='34' flat dense solo background-color='#F0F0F0')
                        template(v-slot:prepend-inner)
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
            v-col.d-flex.justify-end(cols='4')
                v-btn.mt-6(@click='showAddStageDialog' height='40' color='green' depressed dark)
                    v-icon.mr-1(size='14') icon-add
                    span {{$t('PIPELINESTAGESETTING.ADD')}}
        p.text-gary.mb-2 {{$t('PIPELINESTAGESETTING.WORRY')}}
        v-skeleton-loader( v-if='loading' type="table-heading, list-item-two-line")
        v-card.process-setting-item(flat v-if='!loading' v-for='(item, index) in stageList' :key='index')
            v-row.align-center(no-gutters)
                v-col(cols='10')
                    h3.process-setting-item-title {{ item.stage_type_name }}
                v-col.d-flex.justify-end(cols='2')
                    v-checkbox(v-model='item.is_admin_default' :label=`$t('PIPELINESTAGESETTING.DEFAULT_PROCESS')` @change='setAdminDefault(item)' :readonly='item.is_admin_default')
                    v-btn.ml-3(color='gary' rounded icon @click='showEditStageDialog(item, index)')
                        v-icon(size='20') icon-edit
                    v-btn.ml-3(color='gary' rounded icon @click='onDelete(item.id, index)' v-if='stageList.length > 1')
                        v-icon(size='20') icon-remove
                v-col.mt-2(cols='12')
                    v-stepper.process-box(v-if='item.stagedef_set.length <= 6')
                        v-stepper-header.process-bar
                            v-stepper-step(v-for='(subItem, index) in item.stagedef_set' :step='index' :class='`customcolor`' :style='getColor(subItem.stage_color)' :key='index')
                                span(style="color:white;") {{ subItem.stage_name }}
                    v-stepper.process-box(v-else)
                        v-stepper-header.process-bar.mb-2
                            v-stepper-step(v-for='(subItem, index) in item.stagedef_set.slice(0, 6)' :step='index' :class='`customcolor`' :style='getColor(subItem.stage_color)' :key='index')
                                span(style="color:white;") {{ subItem.stage_name }}
                        v-stepper-header.process-bar
                            v-stepper-step(v-for='(subItem, index) in item.stagedef_set.slice(6, 12)' :step='index' :class='`customcolor`' :style='getColor(subItem.stage_color)' :key='index')
                                span(style="color:white;") {{ subItem.stage_name }}
    v-dialog(v-model='addProcessDialog' max-width='500' content-class='setting-dialog' v-if='addProcessDialog' scrollable)
        add-process-dialog(@emitAddProcessDialog='onEmitAddProcessDialog' @emitAddProcessData='onEmitAddProcessData' :stage-type='stageTypeData' :stage-def='stageDefData' :pageType='pageType')
    v-dialog(v-model='editProcessDialog' max-width='500' content-class='setting-dialog' v-if='editProcessDialog' scrollable persistent)
        edit-process-dialog(@emitEditProcessDialog='onEmitEditProcessDialog' @emitEditProcessData='onEmitEditProcessData' :stage-type='stageTypeData' :stage-def='stageDefData')
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
        message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog')
        success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import StageDataService from '@/services/StageDataService';
    import addProcessDialog from '@/components/Dialog/Setting/addProcessDialog';
    import editProcessDialog from '@/components/Dialog/Setting/editProcessDialog';
    import messageDialog from '@/components/Dialog/messageDialog';
    import successDialog from '@/components/Dialog/successDialog';
    import errorDialog from '@/components/Dialog/errorDialog';
    import stageTypeList from "@/array/the_stageTypeList";
    import i18n from '/common/plugins/vue-i18n.js'
    import passData from "@/services/passData.js";

    export default {
        name: 'settingProcess',
        components: {
            addProcessDialog,
            editProcessDialog,
            messageDialog,
            successDialog,
            errorDialog,
        },
        data() {
            return {
                messageDialog: false,
                message: '',
                successDialog: false,
                errorDialog: false,
                errorMessage: '',
                stageList: [],
                addProcessDialog: false,
                editProcessDialog: false,
                stageTypeData: {},
                stageDefData: [],
                pageType:"",
                selectedId: '',
                selectedIndex: '',
                messageContent: '',
                originData: {},
                typeList:stageTypeList,
                stage_type:null,
                stageListAll:[],
                admin_default_stage: null,
                loading: false,
            }
        },
        created() {
            StageDataService.stageList("setting")
            .then(response => {
                this.stageListAll = response.data;
                this.stage_type="DEAL";
            });
            
        },
        methods: {
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            onEmitMessageDialog(val) {
                this.messageDialog = false;
                if (val) {
                    if (this.messageContent == 'DELETE') {
                        StageDataService.delete(this.selectedId)
                        .then(response => {
                            if (response.data == 'Have Opportunity') {
                                this.showErrorDialog(i18n.t('PIPELINESTAGESETTING.ERROR'));
                            }
                            else if(response.data == 'Have Case') 
                            {
                                this.showErrorDialog(i18n.t('PIPELINESTAGESETTING.ERROR_2'));
                            }
                            else {
                                if(typeof(response.data) === 'object'){
                                    // set new system defult
                                    let new_system_default = this.stageList.find(e => e.id == response.data.id);
                                    new_system_default.is_admin_default = true;
                                    this.admin_default_stage = new_system_default;
                                    // set user cache data 
                                    let user = JSON.parse(window.localStorage.getItem('user'));
                                    if(this.stage_type=="DEAL"){
                                        if(user.default_value != null){
                                            user.default_value.default_process.opportunity = null;
                                            let default_filters;
                                            try{
                                                default_filters = user.default_value.default_filter.Opportunity;
                                            }
                                            catch{
                                                default_filters = null;
                                            }
                                            
                                            if( default_filters != null){
                                                if(default_filters[this.selectedId] != undefined){
                                                    delete user.default_value.default_filter.Opportunity[this.selectedId];
                                                }
                                            }
                                        }
                                    }
                                    else if(this.stage_type=="CASE"){
                                        if(user.default_value != null){
                                            user.default_value.default_process.case = null;
                                            let default_filters;
                                            try{
                                                default_filters = user.default_value.default_filter.Case;
                                            }
                                            catch{
                                                default_filters = null;
                                            }

                                            if(default_filters != null){
                                                if(default_filters[this.selectedId] != undefined){
                                                    delete user.default_value.default_filter.Case[this.selectedId];
                                                }
                                            }
                                        }
                                    }
                                    window.localStorage.setItem('user', JSON.stringify(user));
                                }
                                this.showSuccessDialog();
                                this.stageList.splice(this.selectedIndex, 1);
                                passData.$emit("getStageList", 'get_stage');
                            }
                        });
                    } 
                }
            },
            showSuccessDialog() {
                this.successDialog = true;
            },
            onEmitSuccessDialog() {
                this.successDialog = false;
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            onEmitAddProcessDialog(val) {
                if (val) {
                    this.showSuccessDialog();
                }
                this.addProcessDialog = false;
            },
            onEmitAddProcessData(data) {
                passData.$emit("getStageList", 'get_stage');
                this.stageListAll.unshift(data);
                if(data.type == this.stage_type)
                {
                    this.stageList.unshift(data);
                }
            },
            onEmitEditProcessDialog(val,dellist) {
                if (val) {
                    this.showSuccessDialog();
                } else {
                    this.stageList[this.selectedIndex] = this.originData;
                    if(this.originData.is_admin_default){
                        this.admin_default_stage = this.originData;
                    }
                    if(dellist.length>0){
                        for(let i in dellist){
                            this.stageList[this.selectedIndex].stagedef_set = this.stageList[this.selectedIndex].stagedef_set.filter(el => el.id != dellist[i])
                        }
                    }
                }
                this.editProcessDialog = false;
            },
            onEmitEditProcessData(data) {
                passData.$emit("getStageList", 'get_stage');
                this.stageList[this.selectedIndex] = data;
                data.stagedef_set.sort((a, b) => a.sequence_number - b.sequence_number);
                if(data.is_admin_default){
                    this.admin_default_stage = data;
                }
            },
            showAddStageDialog() {
                this.pageType = this.stage_type
                this.stageTypeData = {
                    stage_type_name: '',
                    type:null
                }
                this.stageDefData = [
                    {
                        sequence_number: '',
                        stage_name: '',
                        stage_probability: '',
                        zombie_day: '',
                        guideline: '',
                        stage_color: '',
                        isMore: false,
                    }
                ]
                this.addProcessDialog = true;
            },
            showEditStageDialog(data, index) {
                this.selectedIndex = index;
                this.originData = JSON.parse(JSON.stringify(data));
                this.stageDefData = data.stagedef_set;
                this.stageTypeData.id = data.id;
                this.stageTypeData.stage_type_name = data.stage_type_name;
                this.stageTypeData.type = data.type;
                this.editProcessDialog = true;
            },
            onDelete (id, index) {
                this.selectedId = id;
                this.selectedIndex = index;
                this.showMessageDialog(i18n.t('PIPELINESTAGESETTING.MESSAGE'));
                this.messageContent = 'DELETE';
            },
            checkMove: function(e) {
                window.console.log("Future index: " + e.draggedContext.futureIndex);
            },
            getColor: function(data) {
                this.colorQ = data	 
                return {
                    '--backgroundColor':this.colorQ,
                    '--color': this.colorQ		
                }
            },
            setAdminDefault(item){
                // is_admin_default
                this.admin_default_stage.is_admin_default = false;
                StageDataService.update(this.admin_default_stage)
                .finally(()=>{
                    StageDataService.update(item)
                    .then(() => {
                        this.admin_default_stage = item;
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                })
                .catch(response => {
                    this.showErrorDialog(response);
                })
            }
        },
        watch: {
            "stage_type": async function () {   
                this.stageList = []
                this.loading = true;
                StageDataService.stageList("setting")
                .then(response => {
                    this.stageListAll = response.data;
                })
                .catch(response =>{
                    this.showErrorDialog(response)
                })
                .finally(()=>{
                    this.stageListAll.forEach(item => {
                        
                        if(item.type==this.stage_type)
                        {
                            this.stageList.push(item);
                            if(item.is_admin_default)
                            {
                                this.admin_default_stage = item;
                            }
                        }
                    });
                    this.loading = false;
                })
            }
        }
    }
</script>