<template lang="pug">
    #ManageUsers    
        loadingOverlay(v-if='loading' message='Loading . . .' :zIndex='999')
        v-row.setting-title.align-center.px-4.py-3(no-gutters)
            v-col(cols='12' md='8')
                h3.d-flex.align-center.mb-0
                    .cube-icon.small.bg-primary.mr-3
                        v-icon(size='17' color='white') icon-contact
                    span {{$t('SETTING.PRODUCTSETTING')}}
        .px-5.py-5
            v-row(no-gutters).mb-2
                v-col.d-flex(cols='8')
                    label {{$t('CUSTOMFIELDS.SELECT_PAGE')}}
                        v-select.mr-4(v-model='product_page' :items='page_list' item-value='id' item-text='name' prepend-inner-icon='icon-step' height='34' flat dense solo background-color='#F0F0F0')           
                v-col.d-flex.justify-end(cols='4')        
                    v-text-field.pa-0.mt-6.mr-2(v-model='search' flat dense solo height='40' min-height='40' prepend-inner-icon='icon-search')
                    v-btn.mt-6(@click='showAddProductDialog' height='40' color='green' depressed dark)
                        v-icon.mr-1(size='14') icon-add
                        span {{$t('GENERAL.ADD')}} 
            v-data-table.dashboard-table(
                :headers='useHeader' 
                :items='product_list' 
                :items-per-page='itemPerPage' 
                :page.sync='page' 
                :page-count='pageCount'   
                :sort-desc.sync='sortByDesc'
                :sort-by.sync="sortBy"
                item-class='dataTableColor'
                fixed-header
                multi-sort
                height='715' 
                :header-props="{sortIcon: 'mdi mdi-arrow-up'}"  
                hide-default-footer='hide-default-footer' 
                dense
                v-if='product_page == 0'
            )        
                template(v-slot:item.name='{ item }')
                    .ellipsis.t-black(:title='item.name') {{ item.name}}
                template(v-slot:item.category.name='{ item }')
                    .ellipsis.t-black(:title='item.category.name' v-if='item.category!= null') {{ item.category.name }}
                template(v-slot:item.series.name='{ item }')
                    .ellipsis.t-black(:title='item.series.name' v-if='item.series!= null') {{ item.series.name}}
                template(v-slot:item.product_code='{ item }')
                    .ellipsis.t-black(:title='item.product_code') {{ item.product_code}}
                template(v-slot:item.formatPrice='{ item }')
                    .ellipsis.t-black(:title='item.formatPrice') {{ item.formatPrice}}
                template(v-slot:item.formatCost='{ item }')
                    .ellipsis.t-black(:title='item.formatCost') {{ item.formatCost}}
                template(v-slot:item.description='{ item }')
                    .ellipsis.t-black(:title='item.description') {{ item.description}}
                template(v-slot:item.enable_trq='{ item }')
                    v-icon(v-if='item.enable_trq === true' class='ellipsis' size="18" color="green" ) fas fa-check 
                    span(v-else-if='item.enable_trq === false')
                template(v-slot:item.from_date='{ item }')
                    .ellipsis.t-black(:title='item.from_date') {{ item.from_date}}
                template(v-slot:item.to_date='{ item }')
                    .ellipsis.t-black(:title='item.to_date') {{ item.to_date}}
                template(v-slot:item.tax='{ item }')
                    .ellipsis.t-black(:title='item.tax') {{ item.tax}}
                template(v-slot:item.other_charges='{ item }')
                    .ellipsis.t-black(:title='item.other_charges') {{ item.other_charges}}
                template(v-slot:item.active='{ item }')
                    v-btn(icon @click='showEditProductDialog(item)')
                        v-icon(size='18') icon-edit
                    v-btn(icon @click='onDelete(item)')
                        v-icon(size='20') icon-remove
            v-data-table.dashboard-table(
                :headers='useHeader' 
                :items='categoryList' 
                :items-per-page='itemPerPage' 
                :page.sync='page_1' 
                item-class='dataTableColor'
                fixed-header 
                @page-count='pageCount_1 = $event' 
                :search='search' 
                multi-sort
                height='715' 
                :header-props="{sortIcon: 'mdi mdi-arrow-up'}"  
                hide-default-footer='hide-default-footer' 
                dense
                v-if='product_page == 1'
            )        
                template(v-slot:item.name='{ item }')
                    .ellipsis.t-black(:title='item.name') {{ item.name}}
                template(v-slot:item.description='{ item }')
                    .ellipsis.t-black(:title='item.description') {{ item.description}}
                template(v-slot:item.active='{ item }')
                    v-btn(icon @click='showEditProductDialog(item)')
                        v-icon(size='18') icon-edit
                    v-btn(icon @click='onDelete(item)')
                        v-icon(size='20') icon-remove
            
            v-data-table.dashboard-table(
                :headers='useHeader' 
                :items='seriesList' 
                :items-per-page='itemPerPage' 
                :page.sync='page_2' 
                item-class='dataTableColor'
                fixed-header 
                @page-count='pageCount_2 = $event' 
                :search='search' 
                multi-sort
                height='715' 
                :header-props="{sortIcon: 'mdi mdi-arrow-up'}"  
                hide-default-footer='hide-default-footer' 
                dense
                v-if='product_page == 2'
            )        
                template(v-slot:item.name='{ item }')
                    .ellipsis.t-black(:title='item.name') {{ item.name}}
                template(v-slot:item.description='{ item }')
                    .ellipsis.t-black(:title='item.description') {{ item.description}}
                template(v-slot:item.active='{ item }')
                    v-btn(icon @click='showEditProductDialog(item)')
                        v-icon(size='18') icon-edit
                    v-btn(icon @click='onDelete(item)')
                        v-icon(size='20') icon-remove

            v-pagination.py-1.mb-3(v-if='product_page ==2' v-model='page_2' :length='pageCount_2' circle :total-visible="10" style='position: absolute; bottom: 0; width: 100%; height: 40px')
            v-pagination.py-1.mb-3(v-if='product_page ==1' v-model='page_1' :length='pageCount_1' circle :total-visible="10" style='position: absolute; bottom: 0; width: 100%; height: 40px')
            v-pagination.py-1.mb-3(v-if='product_page == 0' v-model='page' :length='pageCount' circle :total-visible="10" style='position: absolute; bottom: 0; width: 100%; height: 40px')

        
        v-dialog(v-model='addProductDialog' max-width='500' content-class='setting-dialog' v-if='addProductDialog' persistent @keydown.esc='onCancel()')
            v-card.mangeUserCard
                v-card-title.d-flex.justify-center.mb-4 {{labelName}} 
                v-card-text
                    v-form(v-model='valid' ref='form')
                        v-row(no-gutters)    
                            v-col(cols='12')
                                v-text-field.v-text-field__slot.height-58(v-model='addProduct.name' :label=`$t('PRODUCTSETTING.NAME')`, no-resize prepend-icon="fa-file-signature" , :rules='formRules.nameRules')
                        v-row(no-gutters)
                            v-col(cols='12')
                                v-combobox.v-text-field__slot.height-58(v-if='product_page == 0', v-model='addProduct.category' :items="categoryList" item-value="id" item-text="name", :label=`$t('PRODUCTSETTING.CATEGORY')`, no-resize prepend-icon='fa-tag' :rules='formRules.numberRule' ,:ref="'check_category'")
                                    template(v-slot:append-outer)
                                        div(v-if="isNew")
                                            v-icon(color="red", size="25") mdi-new-box
                                    template(v-slot:item="data")
                                        div.ellipsis(v-if="data.item.id == 0")
                                            span {{ data.item.name }}
                                            v-icon(color="red", size="25") mdi-new-box
                                        div.ellipsis(v-else)
                                            span {{ data.item.name }}
                        v-row(no-gutters)
                            v-col(cols='12')
                                v-combobox.v-text-field__slot.height-58(v-if='product_page == 0' v-model='addProduct.series' :items="seriesList" item-value="id" item-text="name", :label=`$t('PRODUCTSETTING.SERIES')`, no-resize prepend-icon='fa-tag' :rules='formRules.numberRule' ,:ref="'check_series'")
                                    template(v-slot:append-outer)
                                        div(v-if="isNew_2")
                                            v-icon(color="red", size="25") mdi-new-box
                                    template(v-slot:item="data")
                                        div.ellipsis(v-if="data.item.id == 0")
                                            span {{ data.item.name }}
                                            v-icon(color="red", size="25") mdi-new-box
                                        div.ellipsis(v-else)
                                            span {{ data.item.name }}
                        v-row(no-gutters)
                            v-col(cols='12')
                                v-text-field.v-text-field__slot.height-58(v-if='product_page == 0' v-model='addProduct.product_code' :label=`$t('PRODUCTSETTING.PRODUCTCODE')`, no-resize prepend-icon="fa-solid fa-barcode" :rules='formRules.numberRule' validate-on-blur,)
                        v-row(no-gutters)
                            v-col(cols='12')
                                v-text-field.v-text-field__slot.height-58(v-if='product_page == 0' v-model='addProduct.unit_price' :label=`$t('PRODUCTSETTING.PRICR')`, no-resize prepend-icon="icon-money" :rules='formRules.amountRule',v-show='priceShow|!priceValidate' :ref="'editPrice'" @blur='showFormatPrice')
                                v-text-field.v-text-field__slot.height-58(v-if='product_page == 0' v-model='formatPrice' prepend-icon='icon-money', :label=`$t('PRODUCTSETTING.PRICR')`,  @click='showEditPrice' @blur='showEditPrice' :readonly='true' v-show='!priceShow && priceValidate')
                        v-row(no-gutters)
                            v-col(cols='12')
                                v-text-field.v-text-field__slot.height-58(v-if='product_page == 0' v-model='addProduct.unit_cost' :label=`$t('PRODUCTSETTING.COST')`, no-resize prepend-icon="icon-money" :rules='formRules.amountRule', ,v-show='costShow|!costValidate' :ref="'editCost'" @blur='showFormatCost')
                                v-text-field.v-text-field__slot.height-58(v-if='product_page == 0' v-model='formatCost' prepend-icon='icon-money', :label=`$t('PRODUCTSETTING.COST')`,  @click='showEditCost' @blur='showEditCost' :readonly='true' v-show='!costShow && costValidate')
                        v-row(no-gutters)
                            v-col(cols='12')
                                v-text-field.v-text-field__slot.height-58(v-if='product_page == 0' v-model.number='addProduct.tax' type='number', :label=`$t('PRODUCTLINEITEM.TAX')`, no-resize prepend-icon="fa-solid fa-barcode" :rules='formRules.amountRule')
                        v-row(no-gutters)
                            v-col(cols='12')
                                v-textarea.textarea-style.no-border.v-text-field__slot(v-model='addProduct.description' :label=`$t('PRODUCTSETTING.DESCRIPTIONL')`, no-resize ,prepend-icon="mdi-equal-box")
                    v-card-actions.mt-8.px-0.py-0
                        v-row(no-gutters)
                            v-col.pr-2(cols='6')
                                v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                            v-col.pl-2(cols='6')
                                v-btn(:ripple='false' @click='onProductAdd(addProduct)' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.ADD')}}
        v-dialog(v-model='editProductDialog' max-width='500' content-class='setting-dialog')
            v-card.mangeUserCard
                v-card-title.d-flex.justify-center.mb-4 {{labelName_edit}} 
                v-card-text
                    v-form(v-model='editProduct.valid' ref='form_edit')
                        v-row(no-gutters)    
                            v-col(cols='12')
                                v-text-field.v-text-field__slot.height-58(v-model='editProduct.name' :label=`$t('PRODUCTSETTING.NAME')`, no-resize prepend-icon="fa-file-signature", :rules='formRules.nameRules',)
                        v-row(no-gutters)
                            v-col(cols='12')
                                v-combobox.v-text-field__slot.height-58(v-if='product_page == 0', v-model='editProduct.category' :items="categoryList" item-value="id" item-text="name", :label=`$t('PRODUCTSETTING.CATEGORY')`, no-resize prepend-icon='fa-tag' :rules='formRules.numberRule' ,:ref="'check_category_edit'")
                                    template(v-slot:append-outer)
                                        div(v-if="isNew")
                                            v-icon(color="red", size="25") mdi-new-box
                                    template(v-slot:item="data")
                                        div.ellipsis(v-if="data.item.id == 0")
                                            span {{ data.item.name }}
                                            v-icon(color="red", size="25") mdi-new-box
                                        div.ellipsis(v-else)
                                            span {{ data.item.name }}
                        v-row(no-gutters)
                            v-col(cols='12')
                                v-combobox.v-text-field__slot.height-58(v-if='product_page == 0' v-model='editProduct.series' :items="seriesList" item-value="id" item-text="name", :label=`$t('PRODUCTSETTING.SERIES')`, no-resize prepend-icon='fa-tag' :rules='formRules.numberRule' ,:ref="'check_series_edit'" )
                                    template(v-slot:append-outer)
                                        div(v-if="isNew_2")
                                            v-icon(color="red", size="25") mdi-new-box
                                    template(v-slot:item="data")
                                        div.ellipsis(v-if="data.item.id == 0")
                                            span {{ data.item.name }}
                                            v-icon(color="red", size="25") mdi-new-box
                                        div.ellipsis(v-else)
                                            span {{ data.item.name }}
                        v-row(no-gutters)
                            v-col(cols='12')
                                v-text-field.v-text-field__slot.height-58(v-if='product_page == 0' v-model='editProduct.product_code' :label=`$t('PRODUCTSETTING.PRODUCTCODE')`, no-resize prepend-icon="fa-solid fa-barcode" :rules='formRules.numberRule')
                        v-row(no-gutters)
                            v-col(cols='12')
                                //- v-text-field.v-text-field__slot.height-58(v-if='product_page == 0' v-model='editProduct.unit_price' :label=`$t('PRODUCTSETTING.PRICR')`, no-resize prepend-icon="icon-money" :rules='formRules.amountRule')
                                v-text-field.v-text-field__slot.height-58(v-if='product_page == 0' v-model='editProduct.unit_price' :label=`$t('PRODUCTSETTING.PRICR')`, no-resize prepend-icon="icon-money" :rules='formRules.amountRule',v-show='priceShow|!priceValidate' :ref="'editPrice_edit'" @blur='showFormatPrice')
                                v-text-field.v-text-field__slot.height-58(v-if='product_page == 0' v-model='formatPrice' prepend-icon='icon-money', :label=`$t('PRODUCTSETTING.PRICR')`,  @click='showEditPrice' @blur='showEditPrice' :readonly='true' v-show='!priceShow && priceValidate')
                        v-row(no-gutters)
                            v-col(cols='12')
                                //- v-text-field.v-text-field__slot.height-58(v-if='product_page == 0' v-model='editProduct.unit_cost' :label=`$t('PRODUCTSETTING.COST')`, no-resize prepend-icon="icon-money" :rules='formRules.amountRule')
                                v-text-field.v-text-field__slot.height-58(v-if='product_page == 0' v-model='editProduct.unit_cost' :label=`$t('PRODUCTSETTING.COST')`, no-resize prepend-icon="icon-money" :rules='formRules.amountRule', ,v-show='costShow|!costValidate' :ref="'editCost_edit'" @blur='showFormatCost')
                                v-text-field.v-text-field__slot.height-58(v-if='product_page == 0' v-model='formatCost' prepend-icon='icon-money', :label=`$t('PRODUCTSETTING.COST')`,  @click='showEditCost' @blur='showEditCost' :readonly='true' v-show='!costShow && costValidate')
                        v-row(no-gutters)
                            v-col(cols='12')
                                v-text-field.v-text-field__slot.height-58(v-if='product_page == 0' v-model.number='editProduct.tax' type='number', :label=`$t('PRODUCTLINEITEM.TAX')`, no-resize prepend-icon="fa-solid fa-barcode" :rules='formRules.amountRule')
                        v-row(no-gutters)
                            v-col(cols='12')
                                v-textarea.textarea-style.no-border.v-text-field__slot(v-model='editProduct.description' :label=`$t('PRODUCTSETTING.DESCRIPTIONL')`, no-resize ,prepend-icon="icon-money")
                    v-card-actions.mt-8.px-0.py-0
                        v-row(no-gutters)
                            v-col.pr-2(cols='6')
                                v-btn(:ripple='false' @click='editProductDialog = false' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                            v-col.pl-2(cols='6')
                                v-btn(:ripple='false' @click='onProductEdit(editProduct)' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.SAVE')}}
        
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
                message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
                success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
                error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
        v-dialog(v-model='addProduct_only' v-if='addProduct_only' width='500' content-class='statusDialog' persistent @keydown.esc='onEmitAddDialog(true)')
            addProduct(@emitAddProductDialog='onEmitAddDialog' :copyProduct='addProduct')
        v-dialog(v-model='editProduct_only' v-if='editProduct_only' width='500' content-class='statusDialog' persistent @keydown.esc='onEmitEditDialog(true)')
            editProduct(@emitEditDialog='onEmitEditDialog' :editProduct='editProduct')
</template>

<script>
    import Vue from 'vue';
    import messageDialog from '@/components/Dialog/messageDialog';
    import ProductDataService from "@/services/ProductDataService";
    import successDialog from "@/components/Dialog/successDialog";
    import errorDialog from "@/components/Dialog/errorDialog";
    import loadingOverlay from "@/components/Common/loadingOverlay";    
    import i18n from '/common/plugins/vue-i18n.js';
    import addProduct from '@/components/Dialog/Product/addProduct';
    import editProduct from '@/components/Dialog/Product/editProduct';

    export default Vue.extend({
        props: {  
            
        },
        components: {  
            loadingOverlay,            
            messageDialog,
            successDialog,
            errorDialog,
            addProduct,
            editProduct,
        },        
        data() {
        return {
            addProduct_only:false,
            editProduct_only:false,
            priceValidate:false,
            costValidate:false,
            formatPrice:null,
            priceShow:false,
            formatCost:null,
            costShow:false,
            loading:false,
            product_page:0,
            page_list:[{id:0,name:i18n.t('PRODUCTSETTING.PRODUCT')},
                        {id:1,name:i18n.t('PRODUCTSETTING.CATEGORY')},
                        {id:2,name:i18n.t('PRODUCTSETTING.SERIES')}],
            productHeaders: [
                {  text: i18n.t('PRODUCTSETTING.NAME'), value: 'name', align: 'left', width: '30%' ,class: "blue lighten-5"},
                {  text: i18n.t('PRODUCTSETTING.CATEGORY'), value: 'category.name', align: 'left', width: '15%',class: "blue lighten-5"},
                {  text: i18n.t('PRODUCTSETTING.SERIES'), value: 'series.name', align: 'center' , width: '15%',class: "blue lighten-5"},
                {  text: i18n.t('PRODUCTSETTING.PRODUCTCODE'), value: 'product_code', align: 'center' , width: '25%',class: "blue lighten-5"},
                {  text: i18n.t('PRODUCTSETTING.PRICR'), value: 'formatPrice', align: 'center' , width: '25%',class: "blue lighten-5"},
                {  text: i18n.t('PRODUCTSETTING.COST'), value: 'formatCost', align: 'center' , width: '25%',class: "blue lighten-5"},
                {  text: i18n.t('PRODUCTLINEITEM.TAX')+" (%)", value: 'tax', align: 'center' , width: '25%',class: "blue lighten-5"},
                {  text: i18n.t('PRODUCTLINEITEM.OTHER_CHARGES')+" (%)", value: 'other_charges', align: 'center' , width: '25%',class: "blue lighten-5"},
                {  text: i18n.t('PRODUCTLINEITEM.ENABLE_TRQ'), value: 'enable_trq', align: 'center' , width: '18%',class: "blue lighten-5"},
                {  text: i18n.t('PRODUCTLINEITEM.FROM'), value: 'from_date', align: 'center' , width: '20%',class: "blue lighten-5"},
                {  text: i18n.t('PRODUCTLINEITEM.TO'), value: 'to_date', align: 'center' , width: '20%',class: "blue lighten-5"},
                {  text: i18n.t('PRODUCTSETTING.DESCRIPTIONL'), value: 'description', align: 'center' , width: '25%',class: "blue lighten-5",sortable: false,},
                {  text: i18n.t('MANAGEUSERS.ACTIVE'), value: 'active', align: 'center', width: '20%' ,class: "blue lighten-5" , sortable: false,},
            ],
            otherHeaders: [
                {  text: i18n.t('PRODUCTSETTING.NAME'), value: 'name', align: 'left', width: '30%' ,class: "blue lighten-5"},
                {  text: i18n.t('PRODUCTSETTING.DESCRIPTIONL'), value: 'description', align: 'center' , width: '25%',class: "blue lighten-5" ,sortable: false,},
                {  text: i18n.t('MANAGEUSERS.ACTIVE'), value: 'active', align: 'center', width: '15%' ,class: "blue lighten-5" , sortable: false,},
            ],
            useList: [],
            useHeader: null,
            product_list:[],
            categoryList:[],
            seriesList:[],
            search:'',
            deleteProduct:false,
            deleteId:null,
            deleteItemIndex:null,

            awaitingSearch: false,
            sortBy:[],
            sortByForSearch:[],
            sortByDesc:[],
            page_1: 1,
            pageCount_1: 1,
            page_2: 1,
            pageCount_2: 1,
            page: 1,
            pageCount: 1,
            itemPerPage: 13,
            searchMangeUser: '',
            addProductDialog: false,
            addProduct : {
                name:null,
                description: null,
                product_code: null,
                unit_price: null,
                unit_cost: null,
                series: null,
                category: null,
                tax:null,
                other_charges:null,
                enable_trq:false,
                from_date:null,
                to_date:null,
            },
            editProductDialog: false,
            editProduct : {                 
                name:null,
                description: null,
                product_code: null,
                unit_price: null,
                unit_cost: null,
                series: null,
                category: null,
                tax:null,
                other_charges:null,
                enable_trq:false,
                from_date:null,
                to_date:null,
            },
            errorMessage:"",
            message:"",
            messageDialog:false,
            errorDialog:false,
            successDialog:false,
            valid:false,

            mangeUserShow: true,
            userData:{},
            max_license:null,
            formRules: {
            nameRules: [
                        (v) => /^(?!\s*$)(?!null$).+$/.test(v) || i18n.t('RULE.RULE_R'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
            amountRule: [(v) => /^\d{1,17}(\.\d{0,2})?$|(^$)|null/.test(v) || i18n.t('RULE.RULE_R_NUM_2')],
            numberRule:[(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
            },            
            labelName:i18n.t('PRODUCTSETTING.ADD_PRODUCT'),
            labelName_edit:i18n.t('PRODUCTSETTING.EDIT_PRODUCT'),
            newCate:false,
            newSeries:false,
            isCreate:false,
        }},
        async created()
        {
            this.useHeader = this.productHeaders
            this.getListData()
            this.getcategorylist()
            this.getserieslist()            
        },
        methods:{
            onEmitEditDialog(data){
                if(data)
                {
                    let editItem = this.product_list.find(el=>{ return el.id == this.editProduct.id});
                    editItem.name = data.name;
                    editItem.description = data.description;
                    editItem.product_code = data.product_code;
                    editItem.unit_price = data.unit_price;
                    editItem.unit_price = data.unit_price;
                    editItem.unit_cost = data.unit_cost;
                    editItem.series = data.series;
                    editItem.category = data.category;
                    editItem.tax = data.tax;
                    editItem.enable_trq=data.enable_trq;
                    editItem.from_date=data.from_date;
                    editItem.to_date=data.to_date;
                    editItem.other_charges=data.other_charges;
                    editItem.formatPrice = this.getFormatAmount(data.unit_price);
                    editItem.formatCost = this.getFormatAmount(data.unit_cost);
                }
                this.editProduct_only = false;
                
            },
            onEmitAddDialog(data){
                if(data)
                {
                    this.product_list.splice(0,0, data)
                    this.showSuccessDialog()
                }
                this.addProduct_only = false;
            },
            async getListData(){
                this.loading = true;
                await ProductDataService.list(this.page,this.search,this.itemPerPage,this.sortByForSearch)
                .then(response => 
                {  
                    this.pageCount = response.data.total_page
                    this.pageDataCount = response.data.pageDataCount
                    this.product_list = response.data.list;
                    for(let item in this.product_list)
                    {
                        this.product_list[item].formatPrice = this.getFormatAmount(this.product_list[item].unit_price)
                        this.product_list[item].formatCost = this.getFormatAmount(this.product_list[item].unit_cost)
                        if(this.product_list[item].category.id ==null)
                        {
                            this.product_list[item].category =null
                        }
                        if(this.product_list[item].series.id ==null)
                        {
                            this.product_list[item].series =null
                        }
                    }
                    this.loading = false;
                });
            },
            async getcategorylist(){
                await ProductDataService.productcategorylist()
                .then(response => 
                {  
                    this.categoryList = response.data;
                });
            },
            async getserieslist(){
                await ProductDataService.productserieslist()
                .then(response => 
                {  
                    this.seriesList = response.data;
                });
            },
            showFormatPrice(val){
                this.priceShow = false;
                if(this.addProductDialog){
                    this.formatPrice = this.getFormatAmount(this.addProduct.unit_price);
                }else if(this.editProductDialog){
                    this.formatPrice = this.getFormatAmount(this.editProduct.unit_price);
                }
                if(val == "edit")
                {
                    this.priceValidate = true;
                }else{
                    if(this.isCreate)
                    {
                        this.priceValidate = this.$refs.editPrice.validate();
                    }else{
                        this.priceValidate = this.$refs.editPrice_edit.validate();
                    }
                    
                }                
            },
            showEditPrice(){
                this.priceShow = true;
                if(this.isCreate)
                    {
                        this.priceValidate = this.$refs.editPrice.validate();
                    }else{
                        this.priceValidate = this.$refs.editPrice_edit.validate();
                    }
            },
            showFormatCost(val){
                this.costShow = false;
                if(this.addProductDialog){
                    this.formatCost = this.getFormatAmount(this.addProduct.unit_cost);
                }else if(this.editProductDialog){
                    this.formatCost = this.getFormatAmount(this.editProduct.unit_cost);
                }
                if(val == "edit")
                {
                    this.costValidate = true;
                }else{                    
                    if(this.isCreate)
                    {
                        this.priceValidate = this.$refs.editCost.validate();
                    }else{
                        this.priceValidate = this.$refs.editCost_edit.validate();
                    }
                }
            },
            showEditCost(){
                this.costShow = true;
                if(this.isCreate)
                {
                    this.priceValidate = this.$refs.editCost.validate();
                }else{
                    this.priceValidate = this.$refs.editCost_edit.validate();
                }
            },
            showAddProductDialog(){
                if(this.product_page == 0){
                    this.addProduct_only = true;
                }
                else{
                    this.isCreate = true;
                    this.addProductDialog = true;
                    this.addProduct = {
                    name:null,
                    description: null,
                    product_code: null,
                    unit_price: null,
                    unit_cost: null,
                    series: null,
                    category: null,
                    tax:null,
                    enable_trq:false,
                    other_charges:null,
                    from_date:null,
                    to_date:null
                }
                
                }
            },
            validateForm(){
                if(this.isCreate){
                    this.valid = this.$refs.form.validate();
                }else{
                    this.valid = this.$refs.form_edit.validate();
                }
                
                if(this.valid)                    
                    return true;
                else
                    return false;
            },
            showSuccessDialog() {
                this.successDialog = true;
            },
            onEmitSuccessDialog() {
                this.successDialog = false;
             },
            showErrorDialog(message) {  
                this.errorDialog = true;      
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            onEmitMessageDialog(val) {
                this.messageDialog = false;
                if(val){
                if(this.deleteProduct == true){
                    if( this.product_page == 0)
                    {
                        ProductDataService.delete(this.deleteId).then((response)=>
                        {
                            
                            if(response.data.error === 'Is using!'){
                                this.showErrorDialog(i18n.t('PRODUCTSETTING.ERROR'));                  
                            }
                            else{
                                 this.product_list.splice(this.deleteItemIndex,1)
                            }
                           
                        })
                    }
                    if( this.product_page == 1)
                    {
                        ProductDataService.productcategorydelete(this.deleteId).then((response)=>
                        {
                            if(response.data.error === 'Is using!'){
                                this.showErrorDialog(i18n.t('PRODUCTSETTING.ERROR_2'));                  
                            }
                            else{
                                this.categoryList.splice(this.deleteItemIndex,1)
                            }
                        })
                    }
                    if( this.product_page == 2)
                    {
                        ProductDataService.productseriesdelete(this.deleteId).then((response)=>
                        {
                            if(response.data.error === 'Is using!'){
                                this.showErrorDialog(i18n.t('PRODUCTSETTING.ERROR_3'));                  
                            }
                            else{
                                this.seriesList.splice(this.deleteItemIndex,1)
                            }
                        })
                    }                    
                }
                }
            },            
            async onProductAdd(data) {
                if(this.product_page == 0){
                    await(this.$refs.check_category.blur())
                    await(this.$refs.check_series.blur())
                }
                // await(this.$refs.check_category.blur())
                // await(this.$refs.check_series.blur())
                if(this.validateForm()){
                this.loading = true
                if( this.product_page == 0)
                {
                    if(typeof(data.series) == "string")
                        {
                            if(data.series.trim() != ''){
                                const name = data.series;
                                data.series = {"id":"0","name" : name};
                                this.newSeries = true;
                            }else{
                                data.series = null;
                            }
                        }
                    if(typeof(data.category) == "string")
                        {
                            if(data.category.trim() != ''){
                                const name = data.category
                                data.category = {"id":"0","name" : name};
                                this.newCate = true;
                            }else{
                                data.category = null;
                            }
                        }
                    if(!data.tax){
                        data.tax = 0;
                    }
                    await ProductDataService.create(data).then((response)=>{
                        response.data.formatPrice = this.getFormatAmount(response.data.unit_price)
                        response.data.formatCost = this.getFormatAmount(response.data.unit_cost)                    
                        this.product_list.splice(0,0, response.data)                     
                        this.showSuccessDialog()
                    })
                    if(this.newSeries)
                    {
                        this.getserieslist();
                        this.newSeries = false;
                    }
                    if(this.newCate)
                    {
                        this.getcategorylist();
                        this.newCate = false;
                    }
                }
                if( this.product_page == 1)
                {
                    let new_data = {name:data.name,description:data.description}                    
                    await ProductDataService.productcategorycreate(new_data).then((response)=>{                    
                        this.categoryList.splice(0,0, response.data)                     
                        this.showSuccessDialog()
                    })
                }
                if( this.product_page == 2)
                {
                    let new_data = {name:data.name,description:data.description}
                    await ProductDataService.productseriescreate(new_data).then((response)=>{                    
                        this.seriesList.splice(0,0, response.data)                     
                        this.showSuccessDialog()
                    })
                }
                this.addProductDialog = false;
                this.loading = false
                this.addProduct =  {
                    name:null,
                    description: null,
                    product_code: null,
                    unit_price: null,
                    unit_cost: null,
                    series: null,
                    category: null,
                    tax:null,
                    enable_trq:false,
                    other_charges:null
                    }
                }
            },
            onCancel(){
                this.addProductDialog = false
                this.addProduct =  {
                    name:null,
                    description: null,
                    product_code: null,
                    unit_price: null,
                    unit_cost: null,
                    series: null,
                    category: null,
                    tax:null,
                    enable_trq:false,
                    other_charges:null,
                    from_date:null,
                    to_date:null
                }            
            },
            async showEditProductDialog(data) {
                if(this.product_page==0){
                    this.editProduct_only = true;
                    this.editProduct.name = data.name;
                    this.editProduct.category = data.category;
                    this.editProduct.series = data.series;
                    this.editProduct.product_code = data.product_code;
                    this.editProduct.description = data.description;
                    this.editProduct.unit_price = data.unit_price;
                    this.editProduct.unit_cost = data.unit_cost;
                    this.editProduct.id = data.id;
                    this.editProduct.tax = data.tax;
                    this.editProduct.enable_trq = data.enable_trq;
                    this.editProduct.other_charges = data.other_charges;
                    this.editProduct.from_date = data.from_date;
                    this.editProduct.to_date = data.to_date;
                }else{
                    this.isCreate = false;
                    this.editProductDialog = true;
                    this.editProduct.name = data.name;
                    this.editProduct.category = data.category;
                    this.editProduct.series = data.series;
                    this.editProduct.product_code = data.product_code;
                    this.editProduct.description = data.description;
                    this.editProduct.unit_price = data.unit_price;
                    this.editProduct.unit_cost = data.unit_cost;
                    this.editProduct.id = data.id;
                    this.editProduct.tax = data.tax;
                    await this.showFormatPrice("edit")
                    await this.showFormatCost("edit")
                }
                
            },
            async onProductEdit(data) {
                if(this.product_page == 0){
                    await(this.$refs.check_category_edit.blur())
                    await(this.$refs.check_series_edit.blur())
                }
                if(this.validateForm()){
                    if( this.product_page == 0)
                    {   
                        this.changeData=this.product_list.filter(editData=>{return editData.id==data.id})[0]
                        if (this.editProduct.series != null) {
                            if (typeof(this.editProduct.series) == "string") {   
                                this.editProduct.series = this.editProduct.series.trim();
                                const name = this.editProduct.series;
                                if (this.editProduct.series == "") {
                                    this.editProduct.series = null;
                                } else {
                                    this.editProduct.series = {"id": "0", "name": name}
                                    this.newSeries = true
                                }
                            } else {
                                this.editProduct.series = data.series.name != "" ? data.series : null;
                            }
                        }

                        if (this.editProduct.category != null) {
                            if (typeof(this.editProduct.category) == "string") {
                                this.editProduct.category = this.editProduct.category.trim();
                                const name = this.editProduct.category;
                                if (this.editProduct.category == "") {
                                    this.editProduct.category = null;
                                } else {
                                    this.editProduct.category = {"id": "0", "name": name}
                                    this.newCate = true
                                }
                            } else {
                                this.editProduct.category = data.category.name != "" ? data.category : null;
                            }
                        }
                        if(!this.editProduct.tax){
                            this.editProduct.tax = 0;
                        }
                        await ProductDataService.edit(this.editProduct).then((response)=>{
                            let editData = this.product_list.find(el => el == this.changeData);
                            editData.name = response.data.name;
                            editData.description = response.data.description;
                            editData.product_code = response.data.product_code;
                            editData.unit_price = response.data.unit_price;
                            editData.unit_cost = response.data.unit_cost;
                            editData.series = response.data.series;
                            editData.category = response.data.category;
                            editData.formatPrice = this.getFormatAmount(editData.unit_price);
                            editData.formatCost = this.getFormatAmount(editData.unit_cost);
                            editData.tax = response.data.tax;
                            this.showSuccessDialog()
                        })
                        if(this.newSeries)
                        {
                            this.getserieslist()
                            this.newSeries = false
                        }
                        if(this.newCate)
                        {
                            this.getcategorylist()
                            this.newCate = false
                        }
                    }
                    if( this.product_page == 1)
                    {
                        this.changeData=this.categoryList.filter(editData=>{return editData.id==data.id})[0]
                        let new_data = {id:data.id,name:data.name,description:data.description}
                        await ProductDataService.productcategoryedit(new_data).then((response)=>{
                            let editData = this.categoryList.find(el => el == this.changeData)
                            editData.name = response.data.name
                            editData.description = response.data.description
                            this.showSuccessDialog()
                        })
                    }
                    if( this.product_page == 2)
                    {
                        this.changeData=this.seriesList.filter(editData=>{return editData.id==data.id})[0]
                        let new_data = {id:data.id,name:data.name,description:data.description}
                        await ProductDataService.productseriesedit(new_data).then((response)=>{
                            let editData = this.seriesList.find(el => el == this.changeData)
                            editData.name = response.data.name
                            editData.description = response.data.description
                            this.showSuccessDialog()
                        })
                    }
                        this.editProductDialog = false;
                    }
            },
            onMail: function (mail) {
                return 'mailto:'+mail;
            },        
            onDelete (item) {                
                this.showMessageDialog(i18n.t('PRODUCTSETTING.MESSAGE'));
                this.deleteProduct =true
                this.deleteId = item.id
                if( this.product_page == 0)
                {
                    this.deleteItemIndex =  this.product_list.indexOf(item);
                }
                if( this.product_page == 1)
                {
                    this.deleteItemIndex =  this.categoryList.indexOf(item);
                }
                if( this.product_page == 2)
                {
                    this.deleteItemIndex =  this.seriesList.indexOf(item);
                }                
                this.deleteData = {id:item.id,is_active:false}
            },
            onReactive(item){
                if(this.isStaff === false)
                {
                    this.showErrorDialog(i18n.t('MANAGEUSERS.ERROR_2'))
                    return
                }
                this.showMessageDialog(i18n.t('MANAGEUSERS.MESSAGE_2'));
                this.reActiveUser = true
                this.reActiveUserID = item.id
                this.reActiveItemIndex =  this.mangeUserListData.indexOf(item);
                this.reActiveData = {id:item.id,is_active:true}            
            }
        },
        computed: {
            isNew: function(){
            let datamode = null
            if(this.addProductDialog)
            {
                datamode = this.addProduct.category
            }
            if(this.editProductDialog)
            {
                datamode = this.editProduct.category
            }
            if(datamode == "" || datamode == null){
                return false;
            }
            if(typeof (datamode) === 'object'){
                if(datamode.id == 0){
                return true;
                }
                else{
                return false;
                }
            }
            else if(typeof (datamode) === 'string'){
                datamode = datamode.trim();
                if(datamode != ''){
                    return true;
                }else{
                    return false;
                }
            }
            else{
                return false;
            }
            },
            isNew_2: function(){
            let datamode = null
            if(this.addProductDialog)
            {
                datamode = this.addProduct.series
            }
            if(this.editProductDialog)
            {
                datamode = this.editProduct.series
            }
            if(datamode == "" || datamode == null){
                return false;
            }
            if(typeof (datamode) === 'object'){
                if(datamode.id == 0){
                return true;
                }
                else{
                return false;
                }
            }
            else if(typeof (datamode) === 'string'){
                datamode = datamode.trim();
                if(datamode != ''){
                    return true;
                }else{
                    return false;
                }
            }
            else{
                return false;
            }
            },
        },
        watch:{
        "product_page":function(){
            if(this.product_page == 0)
            {
                // this.useList = this.product_list
                this.useHeader = this.productHeaders
                this.labelName = i18n.t('PRODUCTSETTING.ADD_PRODUCT')
                this.labelName_edit = i18n.t('PRODUCTSETTING.EDIT_PRODUCT')
            }
            if(this.product_page == 1)
            {
                this.useList = this.categoryList
                this.useHeader = this.otherHeaders
                this.labelName = i18n.t('PRODUCTSETTING.ADD_PRODUCT_2')
                this.labelName_edit = i18n.t('PRODUCTSETTING.EDIT_PRODUCT_2')
                
            }
            if(this.product_page == 2)
            {
                this.useList = this.seriesList
                this.useHeader = this.otherHeaders
                this.labelName = i18n.t('PRODUCTSETTING.ADD_PRODUCT_3')
                this.labelName_edit = i18n.t('PRODUCTSETTING.EDIT_PRODUCT_3')
            }
        },
        "editProductDialog":function(){
            if(!this.editProductDialog)
            {
                this.formatPrice = null;
                this.formatCost = null;
            }            
        },
        "addProductDialog":function(){
            if(!this.addProductDialog)
            {
                this.formatPrice = null;
                this.formatCost = null;
            }
        },
        "page":async function () {      
            await this.getListData()  
        },
        "search":async function () {
        if (!this.awaitingSearch && this.product_page == 0) {
            setTimeout(() => {
                this.page = 1
                this.getListData();
                this.awaitingSearch = false;
            }, 1000);
            }
            this.awaitingSearch = true;
        },   
        "sortByDesc":async function () {   
        this.sortByForSearch = JSON.parse(JSON.stringify(this.sortBy))
        for(let i in this.sortByDesc)
        {
            if(this.sortByDesc[i] == true)
            {
            this.sortByForSearch[i] = '-'+this.sortByForSearch[i]          
            }        
        }
        this.page = 1;
        await this.getListData();
        },
        }
        }
        
        );
</script>